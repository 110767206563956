import {http} from './config';

export default {
    indexTemplate: (filter, page = 1, perpage = null) => {
        if (perpage === 'selecione') perpage = null;
        return http.get('api/v1/modelos?page=' + page, {params:{...filter, perpage}});
    },
    update: (data) => {
        return http.put(`api/v1/propostas/${data.id}`, data);
    },
    show: (id) => {
        return http.get(`api/v1/propostas/${id}`);
    },
    showContract: (id) => {
        return http.get(`api/v1/propostas/${id}?contract=true`);
    },
    showTemplate: (id) => {
        return http.get(`api/v1/modelos/${id}`);
    },
    listTemplate: () => {
        return http.get('api/v1/modelos/listar');
    },
    createTemplate(data) {
        return http.post('api/v1/modelos', data);
    },
    updateTemplate(data, id) {
        return http.put(`api/v1/modelos/${id}`, data);
    },
    deleteTemplate(id) {
        return http.delete(`api/v1/modelos/${id}`);
    },
    alterImpress(id) {
        return http.patch(`api/v1/propostas/impressa/${id}`);
    },
    indexProposal(filter, page = 1, allotmentId = null, perpage = null, exportIn = null) {
        if (perpage === 'selecione' || !perpage) perpage = null;
        let responseType = exportIn === 'xls' ? 'blob' : '';
        return http.get('api/v1/propostas?page=' + page, {
            params: {...filter, perpage, exportIn},
            headers: {'allotment-id': allotmentId},
            responseType,
        });
    },
    reprintProposal(id) {
        return http.post(`api/v1/propostas/reimprimir/${id}`);
    },
    reprintContract(id) {
        return http.post(`api/v1/propostas/reimprimir/${id}?contract=true`);
    },
    rejectProposal(proposal) {
        return http.post(`api/v1/propostas/rejeitar/${proposal.id}`, {motive_reject:proposal.motive_reject});
    },
    aproveProposal(id) {
        return http.post(`api/v1/propostas/aprovar/${id}`);
    },
    solicitClickSign(id) {
        return http.post(`api/v1/propostas/assinatura/${id}`);
    },
    solicitClickSignContract(id) {
        return http.post(`api/v1/contratos/assinatura/${id}`);
    },
    cancelClickSign(id) {
        return http.post(`api/v1/propostas/cancelar-assinatura/${id}`);
    },
    cancelClickSignContract(id) {
        return http.post(`api/v1/contratos/cancelar-assinatura/${id}`);
    },
    showDocumentClickSign(id) {
        return http.get(`api/v1/propostas/visualizar-assinatura/${id}`);
    },
    viewProposalAssinedManual(id) {
        return http.get(`api/v1/propostas/visualizarDocumentoAssinadoManual/${id}`, {responseType: 'blob'});
    },
    deleteProposalAssinedManual(id) {
        return http.delete(`api/v1/propostas/deletarDocumentoAssinadoManual/${id}`);
    },
    regenerateProposal(id) {
        return http.post(`api/v1/propostas/regenerate/${id}`);
    },
    alterProposalForPending(item) {
        return http.post(`api/v1/propostas/pendente/${item.id}`,{motive_pending: item.motive_pending});
    },
    alterProposalForAvaliation(id) {
        return http.post(`api/v1/propostas/avaliacao/${id}`);
    },
    listProposalsForCreateContract({page = 1, allotmentId = null,filter ={}}) {
        return http.get(`api/v1/propostas?page=${page}&status=APROVADA&contract=null`, {
            headers:{'allotment-id':allotmentId},
            params:{...filter},
        });
    },
    watsappClickSing(signer) {
        return http.post(`api/v1/propostas/send-whatsapp/clicksign/signer`, signer);
    },
    indexProposalContract({filter = null, page = 1, allotmentId = null, perpage = null, exportIn = null}) {
        if (perpage === 'selecione' || !perpage) perpage = null;
        let responseType = exportIn === 'xls' ? 'blob' : '';
        return http.get(`api/v1/propostas?page=${page}&onlyContracts=true`, {
            params: {...filter, perpage, exportIn},
            headers: {'allotment-id': allotmentId},
            responseType,
        });
    },
    createContract(businessProposalId) {
        return http.get(`api/v1/contratos/gerar/${businessProposalId}`);
    },
    deleteContract(id) {
        return http.patch(`api/v1/contratos/deletar/${id}`);
    }
}
